import React from 'react'

import '../404.css'
import styled from 'styled-components'

import logo from '../assets/structure/logo-donegertobe.svg'

const ErrorMsg = styled.div`
    background-color: #fff;
    display: flex; 
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    padding: 0 15px;
    @media (min-width: 768px) {
        min-height: 50%;
    }    
`



const Logo = styled.img`
    width: 200px;
    @media (min-width: 768px) {
        width: 275px;
    }  
    @media (min-width: 1024px) {
        width: 350px;
    }  
`
const ErrorText = styled.h1`
    font-family: gopher, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: .05em;
    line-height: 1.25em;
    margin: 1.5em 0 1em;
    color: #D8292F;
    font-size: 30px;
    @media (min-width: 1024px) {
        line-height: 1.25em;
        font-size: 50px;
    }    
`
const ErrorTextAction = styled(ErrorText)`
    font-size: 17px;
    @media (min-width: 1024px) {
        font-size: 25px;
    }    
`



const NotSubscribedHome = () => {

    return (
        <div style={{position: "fixed", top: 0, right:0, left: 0, bottom: 0, zIndex: 99999,height: "100vh", display: "flex", flexDirection: "column"}}>
            <ErrorMsg>
                <div>
                    <Logo src={logo}/>
                    <ErrorText>You are not subscribed</ErrorText>
                    <ErrorTextAction>Please <a href="mailto:info@doneger.com" rel="noreferrer" target="_blank">contact</a> us re-open your account.</ErrorTextAction>
                </div>
            </ErrorMsg>
            
        </div>
    );
};

export default NotSubscribedHome;

