import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth0 } from "../react-auth0-spa";
import { CSSTransition } from 'react-transition-group'
import '../App.css';
import SearchBar from './SearchBar'
//import logo from '../assets/logo-tdg.svg'
//import logo from '../assets/structure/logo-donegertobe.svg'
import logo from '../assets/structure/logo-DT.svg'
import menuOpen from '../assets/icon-menu-open.svg'
import menuClose from '../assets/icon-menu-close.svg'

const Nav = styled.nav` 
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99997;
  height: 56px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  background: #f8f8f8;
`;

const SubNav = styled.nav` 
  display: none;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 99996;
  height: 56px;
  border-bottom: 1px solid #ccc;
  background: #fff;
  -webkit-transition: top .25s;
	-moz-transition: top .25s;
  transition: top .25s;
  top: ${props => (props.more ? "56px" : 0)};
  padding: 0 25%;
  
  @media (min-width: 768px) {
    display: flex;
  }
`;

const NavContainer = styled.nav` 
  display: flex;
  justify-content: space-between;
  width: calc(100% - 60px);

  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavLinkLabel = styled.div`
  
`

const NavLinksContainer = styled.nav` 
  justify-content: space-between;
  width: 100%;
  flex-flow: row wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  display:none;
  @media (min-width: 769px) {
    display:flex;
  }
  ${props => props.isMicro && `
    & div {
        @media (min-width: 768px) {
        display:flex;
        flex-direction: column;
      }
      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }
  `}
`;



const NavItem = styled(NavLink)`
  font-size: 9px;
  letter-spacing: .107em;
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.9) ;
  border-right: 1px solid #ccc;
  text-decoration: none;
  display: flex;
  height: 56px;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;

  &.navbar__link--active {
    color: #d8292F;
  }
  &:hover {
    color: #d8292F;
  }
  @media (max-width: 768px) {
    height: 1em;
    border-right: none;
    font-family: gopher, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: .06em;
    font-size: 28px;
    color: #d8292F;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  @media (min-width: 1024px) {
    font-size: 11px
  }  
  @media (min-width: 1280px) {
    font-size: 14px
  }  
  
`;


const SubNavItem = styled(NavLink)`
  font-size: 13px;
  letter-spacing: .78px;
  text-transform: uppercase;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.9) ;
  text-decoration: none;
  display: flex;
  height: 56px;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;
  -webkit-transition: color .25s;
	-moz-transition: color .25s;
  transition: color .25s;
  &.navbar__link--active {
    color: #d8292F;
  }
  &:hover {
    color: #d8292F;
  }
`;

const NavLogo = styled(NavLink)`
  flex: 70px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 769px) {
    border-right: 1px solid #ccc;
  }
`;

const MenuButton = styled.button`
  position: fixed;
  justify-content: center;
  right:24px;
  top:0;
  outline: none;
  width: 27px;
  padding: 0;
  height: 56px;
  border: none;
  background: none;
  display:flex;
  display: none;
 
  @media (min-width: 769px) {
    display:none;
  }
`;

const MenuButtonClose = styled(MenuButton)`
  width: 19px;
`;

const MobileNav = styled.nav`
  height: 100vh;
  min-height: -webkit-fill-available;
  position: fixed;
  width: 100%;
  z-index: 99998;
  background: #fff;
  


  display: ${props => props.open ? "grid" : "none"};
    grid-template-rows: auto 57px;
    row-gap: 50px;


  opacity: 0;
  padding: 50px 0 20px;
  @media (min-width: 375px) {
    padding: 70px 0 40px;
  }
  @media (min-width: 769px) {
    display:none!important;
  }
`;

const MobileButton = styled.div`
  height: 56px;
  position: fixed;
  right:24px;
  top:0;
  width: 27px;
  z-index: 999992;
  display: flex;
  align-items: center;
  @media (min-width: 769px) {
    display:none;
  }
`

const MobileButtonCap = styled.div`
  display: inline-block;
  cursor: pointer;
`

const Bar1 = styled.div`
  width: 27px;
  height: 1px;
  margin: 3px 0;
  background-color: #333;
  transition: 0.25s;
  ${props => (props.open && `transform: rotate(-45deg) translate(-2.5px, 3px);`)}
`

const Bar3 = styled.div`
  width: 27px;
  height: 1px;
  margin: 3px 0;
  background-color: #333;
  transition: 0.25s;
  ${props => (props.open && `transform: rotate(45deg) translate(-2.5px, -3px);`)}
`

const Bar2 = styled.div`
  width: 27px;
  height: 1px;
  margin: 3px 0;
`

const MobileNavCap = styled.div`
display: grid;
    grid-template-rows: 2fr 1fr;
    row-gap: 50px;
`

const MobileLinks = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
}
`

const MobileSub = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

`

const MobileSubLink = styled(NavLink)`
  color: #222;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0;
  font-size: 13px;
  letter-spacing: .06em;
`

const Logo = styled.img`
  height: 19px;

  @media (min-width: 769px) {
   
  }
`

const CustomCap = styled.div`
  
  display: flex;
  justify-content: center;
  align-self: flex-end;
  width: 100%;
`

const CustomButton = styled(NavLink)`
  height: 57px;
  width: 294px;
  background: #D8292F;
  box-shadow: 0px 3px 6px #CCC;
  border-radius: 28px;
  letter-spacing: .06em;
  font-size: 20px;
  font-family: gopher, sans-serif;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
`

/* const routes = [
  //{ to: '/great-ideas', label: 'Great Ideas' },
  { to: '/merchandising', label: 'Merchandising', jsx: <NavLinkLabel>Merchandising</NavLinkLabel> },
  //{ to: '/creative-direction', label: 'Creative Direction' },
  //{ to: '/business-strategy', label: 'Business Strategy' },
  { to: '/design-movements', label: 'Design Movements', jsx: <NavLinkLabel><span>Design</span> <span>Movements</span></NavLinkLabel> },
  { to: '/color-and-print', label: 'Color + Print', jsx: <NavLinkLabel><span>Color</span> <span>+ Print</span></NavLinkLabel> },
  { to: '/runway-and-research', label: 'Runway + Research', jsx: <NavLinkLabel><span>Runway</span> <span>+ Research</span></NavLinkLabel> },
  { to: '/business-insights', label: 'Business Insights', jsx: <NavLinkLabel><span>Business</span> <span>Insights</span></NavLinkLabel> },
] */

  const routes = [
    //{ to: '/great-ideas', label: 'Great Ideas' },
    { to: '/merchandising', label: 'Merchandising', jsx: <NavLinkLabel>Merchandising</NavLinkLabel> },
    //{ to: '/creative-direction', label: 'Creative Direction' },
    //{ to: '/business-strategy', label: 'Business Strategy' },
    { to: '/design-movements', label: 'Design Movements', jsx: <NavLinkLabel>Design Movements</NavLinkLabel> },
    { to: '/color-and-print', label: 'Color + Print', jsx: <NavLinkLabel>Color + Print</NavLinkLabel> },
    { to: '/runway-and-research', label: 'Runway + Research', jsx: <NavLinkLabel>Runway + Research</NavLinkLabel> },
    { to: '/business-insights', label: 'Business Insights', jsx: <NavLinkLabel>Business Insights</NavLinkLabel> },
  ]

/* const routes = [

  { to: '/creative-direction', label: 'Creative Direction' },
  { to: '/merchandising-guidance', label: 'Merchandising Guidance' },
  { to: '/curation-presentation', label: 'Curation + Presentation' }
] */

const NavBarGrid = ({ open, setOpen, clientInfo }) => {

  const { logout, user } = useAuth0();
  const [more, setMore] = useState(false)

  const triggerLink = () => {
    setMore(false)
  }

  const handleNavbarLink = () => {
    setOpen(!open)
  }

  const links = routes.map(({ to, label, theme,jsx }) => <NavItem key={to} activeClassName="navbar__link--active" onClick={triggerLink} to={to}>{jsx}</NavItem>)
  const mobileLinks = routes.map(({ to, label, open }, index) => <NavItem key={to} open={open} index={index + 1} onClick={handleNavbarLink} to={to}>{label}</NavItem>)

  if(Object.keys(clientInfo).length === 0){
    return <></>
  }


  return (
    <>
    
      <Nav>
        <NavContainer>
          <NavLogo activeClassName="navbar__link--active" className="navbar__link navbar__link__logo" to="/">
            <Logo src={logo} alt="DONEGER | TOBE" width="35" height="19" />
          </NavLogo>
          <NavLinksContainer open={open} isMicro={clientInfo.micro && clientInfo.microPath}>
            {(clientInfo.micro && clientInfo.microPath) ? <NavItem  activeClassName="navbar__link--active" onClick={triggerLink} to={`/custom/${clientInfo.microPath}`}>{clientInfo.displayName}</NavItem> : null}
            {links}
          </NavLinksContainer>
          <SearchBar />
        </NavContainer>
        <MenuButton onClick={handleNavbarLink}><img alt="open menu" src={menuOpen} /></MenuButton>
      </Nav>
      <SubNav more={more}>
        <SubNavItem onClick={triggerLink} to="/contact">Contact</SubNavItem>
        <SubNavItem onClick={triggerLink} to="/terms">Terms</SubNavItem>
        <SubNavItem onClick={triggerLink} to="/privacy">Privacy</SubNavItem>
        <SubNavItem onClick={triggerLink} to="/profile">Profile</SubNavItem>
        <SubNavItem onClick={() => logout()} to="/logout">Log Out</SubNavItem>
      </SubNav>


      <CSSTransition timeout={0} classNames='menuMobile' in={open}>
        <MobileNav id="mobileNav" open={open}>
          <MenuButtonClose onClick={handleNavbarLink}><img alt="close menu" src={menuClose} /></MenuButtonClose>
          <MobileNavCap>
            <MobileLinks>
              <NavItem onClick={handleNavbarLink} to={"/"}>Home</NavItem>
              {
                mobileLinks
              }
            </MobileLinks>
            <MobileSub>
              <MobileSubLink onClick={handleNavbarLink} to="/search">Search</MobileSubLink>
              <MobileSubLink onClick={handleNavbarLink} to="/contact">Contact</MobileSubLink>
              <MobileSubLink onClick={handleNavbarLink} to="/terms">Terms</MobileSubLink>
              <MobileSubLink onClick={handleNavbarLink} to="/privacy">Privacy</MobileSubLink>
              <MobileSubLink onClick={() => logout()} to="/logout">Log Out</MobileSubLink>
            </MobileSub>
          </MobileNavCap>
          <CustomCap>
            <CustomButton onClick={handleNavbarLink} to="/custom-work">Your Custom Work</CustomButton>
          </CustomCap>
        </MobileNav>
      </CSSTransition>

      <MobileButton onClick={handleNavbarLink}>
        <MobileButtonCap>
          <Bar1 open={open} />
          <Bar2 />
          <Bar3 open={open} />
        </MobileButtonCap>
      </MobileButton>



    </>
  );
}

export default NavBarGrid;
