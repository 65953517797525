// src/App.js

import React, { useState, lazy, Suspense } from "react"
import './App.css'
//import './Ppw.css'
import NavBarGrid from "./components/NavBarGrid"
import NavBarPublic from './components/NavBarPublic'

import CustomButton from "./components/CustomButton"
import { Router, Route, Switch, Redirect } from "react-router-dom"

import Home from "./components/Home"
import Footer from "./components/Footer"
//import Terms from "./views/Terms"
import Privacy from "./views/Privacy"
import Contact from "./views/Contact"
import AboutUs from "./views/AboutUs"
import Error404 from "./views/Error404"

import ForgotPassword from './views/ForgotPassword'
import history from "./utils/history"
import PrivateRoute from "./components/PrivateRoute"
import GAWrapper from './components/GAWrapper'

import styled from 'styled-components'
import { useAuth0 } from "./react-auth0-spa";
import PrivateMicroEntry from "./views/PrivateMicroEntry"
import NotSubscribed from "./views/NotSubscribed"
import NotSubscribedHome from "./views/NotSubscribedHome"
//import { MicroManager } from "./components/microsite/MicroManager"
//import {Pills} from "./views/PrivateTab"
//import PostPayWall from "./views/PostPayWall"

//import Hotjar from "./components/trackers/Hotjar"

const Terms = lazy(() => import('./views/Terms'));



const Presentation = lazy(() => import('./views/Presentation'));
const SlugRedirect = lazy(() => import('./views/SlugRedirect'));
const Preview = lazy(() => import('./views/Preview'));
const GreatIdeas = lazy(() => import('./views/GreatIdeas'));
//const Merchandising = lazy(() => import('./views/Merchandising'));
const MerchandisingPaged = lazy(() => import('./views/MerchandisingPaged'));
//const GreatIdea = lazy(() => import('./views/GreatIdea'));
//const BusinessStrategies = lazy(() => import('./views/BusinessStrategies'));
//const ConsumerInsights = lazy(() => import('./views/ConsumerInsights'));
const BusinessTopics = lazy(() => import('./views/BusinessTopics'));
const BusinessInsights = lazy(() => import('./views/BusinessInsights'));
const BusinessInsightsByYear = lazy(() => import('./views/BusinessInsightsByYear'));
const CustomProjects = lazy(() => import('./views/CustomProjects'));
const ClientProjects = lazy(() => import('./views/ClientProjects'));
const CustomProject = lazy(() => import('./views/CustomProject'));
//const SearchResults = lazy(() => import('./views/SearchResults'));
const SearchResultsPaged = lazy(() => import('./views/SearchResultsPaged'));
const Complimentary = lazy(() => import('./views/Complimentary'));
const ProspectProject = lazy(() => import('./views/ProspectProject'));
//const RunwayResearch = lazy(() => import('./views/RunwayResearch'));
const RunwayResearchPaged = lazy(() => import('./views/RunwayResearchPaged'));
//const DesignMovements = lazy(() => import('./views/DesignMovements'));
const DesignMovementsPaged = lazy(() => import('./views/DesignMovementsPaged'));
//const ColorPrint = lazy(() => import('./views/ColorPrint'));
const ColorPrintPaged = lazy(() => import('./views/ColorPrintPaged'));
//const CreativeDirection = lazy(() => import('./layouts/CreativeDirection'));
const Notes = lazy(() => import('./views/Notes'));
const RedirectPage = lazy(() => import('./views/RedirectPage'));
const PostPayWallPaged = lazy(() => import('./views/PostPayWallPaged'));
const PrivateTab = lazy(() => import('./views/PrivateTab'));
//const ReRoute = lazy(() => import('./components/ReRoute'));


const Container = styled.div` 
      padding: 110px 15px 0; 
      width: 100%;
      background: #f8f8f8;
      margin: 0 auto; 
      min-height: 101vh;
      float: left;
      @media (min-width: 768px) {
        padding: 110px 15px 0; 
      }  
      @media (min-width: 1024px) {
        padding: 140px 10% 0; 
      }   
      @media (min-width: 1550px) {
        padding: 140px 15px 0; 
      }  
`;


function App() {


  const { user, clientInfo} = useAuth0();

  const [menuOpen, setMenuOpen] = useState(false)



  return (
    <div className="App">
      {/* <Hotjar/> */}
      <Router history={history}>
        {user ?
          <>
            <NavBarGrid open={menuOpen} setOpen={setMenuOpen} clientInfo={clientInfo}/>
            {/* <CustomButton open={menuOpen} setOpen={setMenuOpen} /> */}
          </>
          :
          <><NavBarPublic /></>
        }
        <Container>
          <Route render={({ location }) => {
            const { pathname, key } = location;
            return (
              <GAWrapper>
                {/* <Route render={tracker} /> */}
                <Switch location={location} >

                  <Route path="/" exact component={Home} />
                  <Route path="/terms" exact component={Terms} />
                  {/* <Route path="/about" exact component={<AboutUs />} /> */}
                  <Route exact path="/about"
                    render={routeProps => (
                      <Suspense fallback={<></>}><AboutUs {...routeProps} key="about" /></Suspense>
                    )}
                  />
                  <Route path="/privacy" exact component={Privacy} />

                  <Route path="/logout" exact component={Home} />
                  <Route path="/contact" exact component={Contact} />
                  {/*                 <PrivateRoute exact path="/search" component={SearchResults} key="search"/>
 */}
                  {/* 
                  <PrivateRoute exact path="/home"
                    render={routeProps => (
                      <Suspense fallback={<></>}><PostPayWall {...routeProps} key="PostPayWall" /></Suspense>
                    )}
                  /> */}

                  <PrivateRoute exact path="/home"
                    render={routeProps => (
                      <Suspense fallback={<></>}><PostPayWallPaged {...routeProps} key="PostPayWallPaged" /></Suspense>
                    )}
                  />

{/* <PrivateRoute exact path="/private"
                    render={routeProps => (
                      <Suspense fallback={<></>}><PrivateTab {...routeProps} key="Private" /></Suspense>
                    )}
                  />

<PrivateRoute
                    path='/private/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><PrivateMicroEntry {...routeProps} slug={routeProps.match.params.slug} key={key} /></Suspense>
                    )}
                  /> */}


                  {/*                   <PrivateRoute exact path="/search"
                    render={routeProps => (
                      <Suspense fallback={<></>}><SearchResults {...routeProps} key="search" /></Suspense>
                    )}
                  /> */}

                  <PrivateRoute exact path="/search"
                    render={routeProps => (
                      <Suspense fallback={<></>}><SearchResultsPaged {...routeProps} key="search-paged" /></Suspense>
                    )}
                  />

                  {/*              <PrivateRoute exact path="/great-ideas"
                    render={routeProps => (
                      <Suspense fallback={<></>}><SlugRedirect {...routeProps} key="great-ideas" /></Suspense>
                    )}
                  /> */}

                  <PrivateRoute exact path="/great-ideas"
                    render={routeProps => (
                      <Suspense fallback={<></>}><GreatIdeas {...routeProps} key="great-ideas" /></Suspense>
                    )}
                  />


                  <PrivateRoute
                    path='/great-ideas/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />

                  {/*             <PrivateRoute
                    path='/great-ideas/page/:page'
                    render={routeProps => (
                      <GreatIdeasPaged {...routeProps} page={routeProps.match.params.page} pathname={pathname} key={key} />
                    )}
                  /> 
  {/*                 <PrivateRoute
                    path='/great-ideas/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><SlugRedirect {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />
 */}


                  <PrivateRoute exact path="/business-strategy"
                    render={routeProps => (
                      <Suspense fallback={<></>}><BusinessTopics {...routeProps} key="business-strategy" /></Suspense>
                    )}
                  />
                  {/*                 <PrivateRoute exact path="/business-strategy/page/:slug" 
                  render={routeProps => (
                    <Suspense fallback={<></>}><Profile/></Suspense>
                  )}
                />    */}
                  <PrivateRoute
                    path='/business-strategy/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><SlugRedirect {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />



                  {/*                <PrivateRoute exact path="/consumer-insights"
                    render={routeProps => (
                      <Suspense fallback={<></>}><ConsumerInsights {...routeProps} key="consumer-insights" userx={user} /></Suspense>
                    )}
                  />
                  <PrivateRoute
                    path='/consumer-insights/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  /> */}

                  {/*                   <PrivateRoute path="/creative-direction2"
                    render={routeProps => (
                      <Suspense fallback={<></>}><CreativeDirection {...routeProps} key="creative-direction2" slug={routeProps.match.params.slug} /></Suspense>
                    )}
                  /> */}

                  {/*                   <PrivateRoute exact path="/merchandising"
                    render={routeProps => (
                      <Suspense fallback={<></>}><Merchandising {...routeProps} key="merchandising" /></Suspense>
                    )}
                  /> */}

                  <PrivateRoute exact path="/merchandising"
                    render={routeProps => (
                      <Suspense fallback={<></>}><MerchandisingPaged {...routeProps} key="merchandising" /></Suspense>
                    )}
                  />


                  <PrivateRoute
                    path='/merchandising/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />


                  {/* ********* NEW SECIONS 2023 ********** */}
                  {/*       <Redirect exact from="/creative-direction/catwalk" to="/runway-and-research" />
                  <Redirect exact from="/creative-direction/travel-guides" to="/runway-and-research" />
                  <Redirect exact from="/creative-direction/travel-guides/:slug" to="/runway-and-research/:slug" /> */}
                  <Redirect exact from="/creative-direction" to="/design-movements" />
                  <Redirect exact from="/creative-direction/color" to="/color-and-print" />
                  <Redirect exact from="/creative-direction/concept" to="/design-movements" />

                  <Redirect exact from="/creative-direction/catwalk" to="/design-movements" />
                  <Redirect exact from="/creative-direction/travel-guides" to="/design-movements" />
                  <Redirect exact from="/creative-direction/travel-guides/:slug" to="/design-movements" />

                  {/*                   <PrivateRoute exact path="/runway-and-research"
                    render={routeProps => (
                      <Suspense fallback={<></>}><RunwayResearch {...routeProps} key="runway-and-research" /></Suspense>
                    )}
                  /> */}

                  <PrivateRoute exact path="/runway-and-research"
                    render={routeProps => (
                      <Suspense fallback={<></>}><RunwayResearchPaged {...routeProps} key="runway-and-research" /></Suspense>
                    )}
                  />

                  <PrivateRoute
                    path='/runway-and-research/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />



                  {/*       <Route exact path="/consumer-insights">
                    <Redirect to="/business-topics" />
                  </Route> */}
                  <Redirect exact from="/consumer-insights" to="/business-insights" />

                  {/*                   <PrivateRoute exact path="/business-insights"
                    render={routeProps => (
                      <Suspense fallback={<></>}><BusinessTopics {...routeProps} key="consumer-insights" userx={user} /></Suspense>
                    )}
                  /> */}

                  <PrivateRoute exact path="/business-insights"
                    render={routeProps => (
                      <Suspense fallback={<></>}><BusinessInsights {...routeProps} key="business-insights" userx={user} /></Suspense>
                    )}
                  />


                  <Redirect from="/consumer-insights/:slug" to="/business-insights/:slug" />

                  <PrivateRoute
                    path='/business-insights/year/:year'
                    render={routeProps => (
                      <Suspense fallback={<></>}><BusinessInsightsByYear {...routeProps} slug={routeProps.match.params.year} pathname={pathname} key={key} /></Suspense>
                    )}
                  />

                  <PrivateRoute
                    path='/business-insights/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />



                  {/*                   <PrivateRoute exact path="/merchandising-guidance"
                    render={routeProps => (
                      <Suspense fallback={<></>}><Merchandising {...routeProps} key="merchandising-guidance" /></Suspense>
                    )}
                  />
                  <PrivateRoute
                    path='/merchandising-guidance/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  /> */}

                  {/*         <PrivateRoute exact path="/curation-presentation"
                    render={routeProps => (
                      <Suspense fallback={<></>}><BusinessStrategies {...routeProps} key="curation_presentation" /></Suspense>
                    )}
                  />
                  <PrivateRoute
                    path='curation-presentation/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  /> */}


                  {/*                   <PrivateRoute path="/creative-direction"
                    render={routeProps => (
                      <Suspense fallback={<></>}><CreativeDirection {...routeProps} key="creative-direction" slug={routeProps.match.params.slug} /></Suspense>
                    )}
                  /> */}


                  {/*                   <PrivateRoute exact path="/design-movements"
                    render={routeProps => (
                      <Suspense fallback={<></>}><DesignMovements {...routeProps} key="design-movements" slug={routeProps.match.params.slug} /></Suspense>
                    )}
                  /> */}

                  <PrivateRoute exact path="/design-movements"
                    render={routeProps => (
                      <Suspense fallback={<></>}><DesignMovementsPaged {...routeProps} key="design-movements-paged" slug={routeProps.match.params.slug} /></Suspense>
                    )}
                  />




                  <PrivateRoute
                    path='/design-movements/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />


                  {/*                   <PrivateRoute exact path="/color-and-print"
                    render={routeProps => (
                      <Suspense fallback={<></>}><ColorPrint {...routeProps} key="color-and-print" slug={routeProps.match.params.slug} /></Suspense>
                    )}
                  /> */}

                  <PrivateRoute exact path="/color-and-print"
                    render={routeProps => (
                      <Suspense fallback={<></>}><ColorPrintPaged {...routeProps} key="color-and-printPaged" slug={routeProps.match.params.slug} /></Suspense>
                    )}
                  />

                  <PrivateRoute
                    path='/color-and-print/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />



                  <PrivateRoute exact path="/the-weekly/:slug"
                    render={routeProps => (
                      <Suspense fallback={<></>}><Presentation {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />

                  {/* ********* END SECIONS 2023 ********** */}

                  {/*                  <PrivateRoute
                    path='/travel-guides/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><ReRoute {...routeProps} reroute={`/creative-direction/travel-guides/${routeProps.match.params.slug}`} key="travel-guides-reroute" /></Suspense>
                    )}
                  /> */}

                  <PrivateRoute exact path="/custom-work"
                    render={routeProps => (
                      <Suspense fallback={<></>}><CustomProjects {...routeProps} key="custom-work" /></Suspense>
                    )}
                  />
                  <PrivateRoute
                    path='/custom-work/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><CustomProject {...routeProps} slug={routeProps.match.params.slug} mode={`customWork`} pathname={pathname} key={key} /></Suspense>
                    )}
                  />
                  <PrivateRoute
                    path='/client/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><ClientProjects {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />

                  <PrivateRoute exact
                    path='/web/pages/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><RedirectPage {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />
                  <PrivateRoute exact
                    path='/web/Inspiration-Guide/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><RedirectPage {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />

                  <PrivateRoute exact
                    path='/web/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><RedirectPage {...routeProps} slug={routeProps.match.params.slug} pathname={pathname} key={key} /></Suspense>
                    )}
                  />

                  <PrivateRoute
                    path='/preview/:entryId'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Preview {...routeProps} entryId={routeProps.match.params.entryId} mode={`preview`} pathname={pathname} key={key} /></Suspense>
                    )}
                  />
                  <Route
                    exact
                    path='/complimentary/contact'
                    render={routeProps => (
                      <Contact {...routeProps} />
                    )}
                  />
                  <Route
                    path='/complimentary/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Complimentary {...routeProps} slug={routeProps.match.params.slug} mode={`public`} pathname={pathname} key={key} /></Suspense>
                    )}
                  />

                  <Route
                    exact
                    path='/projects/:project/contact'
                    render={routeProps => (
                      <Contact project={routeProps.match.params.project} />
                    )}
                  />

                  <Route
                    path='/projects/:project/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Complimentary {...routeProps} project={routeProps.match.params.project} slug={routeProps.match.params.slug} mode={`project`} pathname={pathname} key={key} /></Suspense>
                    )}
                  />
                  <Route

                    path='/projects/:project'
                    render={routeProps => (
                      <Suspense fallback={<></>}><ProspectProject {...routeProps} project={routeProps.match.params.project} pathname={pathname} key={key} /></Suspense>
                    )}
                  />


                  {/*                  <Route
                  exact
                  path='/profile'
                  render={routeProps => (
                    <Profile {...routeProps} pathname={pathname} key={key} />
                    )}
                />     */}

                  <Route
                    path='/forgot-password'
                    render={routeProps => (
                      <ForgotPassword />
                    )}
                  />

                  {/*                   <Route
                    path='/about'
                    exact
                    render={routeProps => (
                      <AboutUs />
                    )}
                  /> */}



                  <Route
                    path='/notes'
                    render={routeProps => (
                      <Suspense fallback={<></>}><Notes {...routeProps} key="notes" /></Suspense>
                    )}
                  />

<Route
                    path='/not-subscribed'
                    render={routeProps => (
                      <Suspense fallback={<></>}><NotSubscribedHome {...routeProps} key="ntsubbedhome" /></Suspense>
                    )}
                  />


<PrivateRoute
                    path='/custom/:privatePath'
                    exact
                    render={routeProps => (
                      <Suspense fallback={<></>}>
                         <PrivateTab {...routeProps} key="Private" privatePath={routeProps.match.params.privatePath} microTags={clientInfo.microTags}/>

                      </Suspense>
                    )}
                  />

                  <PrivateRoute
                    path='/custom/:privatePath/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}>
                          <PrivateMicroEntry {...routeProps} slug={routeProps.match.params.slug} key={key} /* displayName={clientInfo.displayName} *//>
                      </Suspense>
                    )}
                  />


{/*                   <PrivateRoute
                    path='/:privatePath'
                    exact
                    render={routeProps => (
                      <Suspense fallback={<></>}>
  
               <MicroManager {...routeProps} privatePath={routeProps.match.params.privatePath} pathname={pathname} key={key} clientInfo={clientInfo}>
                                  <PrivateTab {...routeProps} key="Private" privatePath={routeProps.match.params.privatePath} microTags={clientInfo.microTags}/>
                          </MicroManager>  
                      </Suspense>
                    )}
                  /> */}

{/*                   <PrivateRoute
                    path='/:privatePath/:slug'
                    render={routeProps => (
                      <Suspense fallback={<></>}>
                      <MicroManager {...routeProps} privatePath={routeProps.match.params.privatePath} pathname={pathname} key={key} clientInfo={clientInfo}>
                          <PrivateMicroEntry {...routeProps} slug={routeProps.match.params.slug} key={key} displayName={clientInfo.displayName}/>
                      </MicroManager>
                      </Suspense>
                    )}
                  /> */}

                  


                  <Route
                    path="*"
                    render={routeProps => (
                      <Error404 {...routeProps} />
                    )}
                  />


                </Switch>
              </GAWrapper>
            )
          }} />

          {user && <CustomButton open={menuOpen} setOpen={setMenuOpen} />

          }
        </Container>
        <Footer />
      </Router>

    </div>
  );
}

export default App;